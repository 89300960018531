/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import '../../src/sass/fontawesome5/variables';
@import '../../src/sass/fontawesome5/mixins';
@import '../../src/sass/fontawesome5/core';
@import '../../src/sass/fontawesome5/larger';
@import '../../src/sass/fontawesome5/fixed-width';
@import '../../src/sass/fontawesome5/list';
@import '../../src/sass/fontawesome5/bordered-pulled';
@import '../../src/sass/fontawesome5/animated';
@import '../../src/sass/fontawesome5/rotated-flipped';
@import '../../src/sass/fontawesome5/stacked';
@import '../../src/sass/fontawesome5/icons';
@import '../../src/sass/fontawesome5/screen-reader';

@import "../../src/sass/fontawesome5/brands";
@import "../../src/sass/fontawesome5/light";
@import "../../src/sass/fontawesome5/regular";
@import "../../src/sass/fontawesome5/solid";
@import "../../src/sass/fontawesome5/shims";
@import "../../src/sass/fontawesome5/duotone";
